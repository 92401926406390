/**
 * @generated SignedSource<<2afa551ebe8740520ae716b49f6e309a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AIJourneysPerformancePageQuery$variables = {
  companyId: string;
};
export type AIJourneysPerformancePageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AIJourneysCurrentStatusCardFragment_query" | "DataFilterOptionsProviderFragment_query" | "useHasAIJourneysAccessFragment_query">;
};
export type AIJourneysPerformancePageQuery = {
  response: AIJourneysPerformancePageQuery$data;
  variables: AIJourneysPerformancePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "companyId",
  "variableName": "companyId"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "values": [
    "true"
  ]
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AIJourneysPerformancePageQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "useHasAIJourneysAccessFragment_query"
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "AIJourneysCurrentStatusCardFragment_query"
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "DataFilterOptionsProviderFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AIJourneysPerformancePageQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v1/*: any*/),
          {
            "kind": "Literal",
            "name": "flagNames",
            "value": [
              "ENABLE_SUBSCRIPTION_OPT_IN_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_PRODUCT_VIEW_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_PAGE_VIEW_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_EVENT_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_ATC_EVENT_SPLIT_FOR_AUTO_JOURNEYS"
            ]
          }
        ],
        "concreteType": "FeatureFlagConnection",
        "kind": "LinkedField",
        "name": "featureFlags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlagEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeatureFlag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Rollout",
                    "kind": "LinkedField",
                    "name": "rolloutVariants",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "variation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "percent",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filters",
                    "value": [
                      {
                        "dimensionId": "include_for_total_performance",
                        "list": (v5/*: any*/),
                        "operator": "FILTER_OPERATOR_IN"
                      },
                      {
                        "dimensionId": "is_ai_journeys",
                        "list": (v5/*: any*/),
                        "operator": "FILTER_OPERATOR_IN"
                      }
                    ]
                  },
                  {
                    "kind": "Literal",
                    "name": "groupings",
                    "value": [
                      {
                        "dimensionId": "date",
                        "granularity": "TIME_DIMENSION_GRANULARITY_DAILY"
                      }
                    ]
                  },
                  {
                    "kind": "Literal",
                    "name": "metricIds",
                    "value": [
                      "ai_journeys_funnel_clicks"
                    ]
                  }
                ],
                "concreteType": "Metric",
                "kind": "LinkedField",
                "name": "metrics",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MetricDefinition",
                    "kind": "LinkedField",
                    "name": "definition",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "metricId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GroupedMetricValue",
                    "kind": "LinkedField",
                    "name": "groupedValues",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DimensionValue",
                        "kind": "LinkedField",
                        "name": "groupingDimensions",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "metrics(filters:[{\"dimensionId\":\"include_for_total_performance\",\"list\":{\"values\":[\"true\"]},\"operator\":\"FILTER_OPERATOR_IN\"},{\"dimensionId\":\"is_ai_journeys\",\"list\":{\"values\":[\"true\"]},\"operator\":\"FILTER_OPERATOR_IN\"}],groupings:[{\"dimensionId\":\"date\",\"granularity\":\"TIME_DIMENSION_GRANULARITY_DAILY\"}],metricIds:[\"ai_journeys_funnel_clicks\"])"
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d44265d21db5144610e664f341674863",
    "id": null,
    "metadata": {},
    "name": "AIJourneysPerformancePageQuery",
    "operationKind": "query",
    "text": "query AIJourneysPerformancePageQuery(\n  $companyId: ID!\n) {\n  ...useHasAIJourneysAccessFragment_query_MWiJC\n  ...AIJourneysCurrentStatusCardFragment_query_MWiJC\n  ...DataFilterOptionsProviderFragment_query_MWiJC\n}\n\nfragment AIJourneysCurrentStatusCardFragment_query_MWiJC on Query {\n  featureFlags(companyId: $companyId, flagNames: [\"ENABLE_SUBSCRIPTION_OPT_IN_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_PRODUCT_VIEW_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_PAGE_VIEW_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_EVENT_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_ATC_EVENT_SPLIT_FOR_AUTO_JOURNEYS\"]) {\n    edges {\n      node {\n        name\n        rolloutVariants {\n          variation\n          percent\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment DataFilterOptionsProviderFragment_query_MWiJC on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      metrics(metricIds: [\"ai_journeys_funnel_clicks\"], filters: [{dimensionId: \"include_for_total_performance\", operator: FILTER_OPERATOR_IN, list: {values: [\"true\"]}}, {dimensionId: \"is_ai_journeys\", operator: FILTER_OPERATOR_IN, list: {values: [\"true\"]}}], groupings: [{dimensionId: \"date\", granularity: TIME_DIMENSION_GRANULARITY_DAILY}]) {\n        definition {\n          name\n          metricId\n        }\n        groupedValues {\n          value\n          groupingDimensions {\n            key\n            value\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment useHasAIJourneysAccessFragment_query_MWiJC on Query {\n  featureFlags(companyId: $companyId, flagNames: [\"ENABLE_SUBSCRIPTION_OPT_IN_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_PRODUCT_VIEW_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_PAGE_VIEW_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_EVENT_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_ATC_EVENT_SPLIT_FOR_AUTO_JOURNEYS\"]) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a618a9a360a0c219b48c4f729cde706";

export default node;
